import '../node_modules/@babel/polyfill'

window.URLSearchParams = window.URLSearchParams || function (searchString) {
  var self = this;
  self.searchString = searchString;
  self.get = function (name) {
    var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(self.searchString);
    if (results == null) {
      return null;
    }
    else {
      return decodeURI(results[1]) || 0;
    }
  };
}

import Vue from 'vue'
import VueResource from 'vue-resource'
import VueFormGenerator from 'vue-form-generator'
import VueSlider from 'vue-slider-component'
import jstz from 'jstz';

import App from './App.vue'

import store from './services/store'
import {Dictionary} from './services/mixins'

import GoogleMapsApiLoader from 'google-maps-api-loader'

import VueRouter from 'vue-router';


import fieldCustomcheckbox from "./components/Checkbox.vue";
Vue.component("fieldCustomcheckbox", fieldCustomcheckbox);
import fieldCustomGoogleAddress from "./components/GoogleAddress.vue";
Vue.component("fieldCustomGoogleAddress", fieldCustomGoogleAddress);


import BrowserDetect from '../node_modules/@metabolism/framework/browser'

BrowserDetect();

Vue.use(VueResource);
Vue.use(VueFormGenerator);
Vue.use(VueRouter);

Vue.mixin(Dictionary);

Vue.component('VueSlider', VueSlider);

const timezone = jstz.determine();

function storeTimezone() {
  let d = new Date();
  d.setTime(d.getTime() + (30*24*60*60*1000));
  let expires = "expires="+ d.toUTCString();
  document.cookie = "timezone=" + timezone.name()+ ";" + expires + ";path=/";
}

Vue.config.productionTip = false;
Vue.http.options.root = process.env.VUE_APP_API_ENDPOINT;

storeTimezone();

// Fonction pour vérifier si le site est en maintenance
async function checkMaintenance() {
  try {
    // Envoie une requête HEAD pour vérifier l'existence de .maintenance
    const response = await fetch('/.maintenance', { method: 'HEAD' });

    // Si le fichier existe (réponse 200), afficher le message de maintenance
    if (response.ok) {
      document.body.innerHTML = "<h1 style='font-size: 24px'>Site en maintenance</h1><p style='font-size: 16px'>Le site est actuellement en maintenance. Merci de revenir plus tard.</p>";
    } else {
      store.dispatch('loadConfig').then(() => {
        new Vue({ store, render: h => h(App) }).$mount('#app');
      });
    }
  } catch (error) {
    store.dispatch('loadConfig').then(() => {
      new Vue({ store, render: h => h(App) }).$mount('#app');
    });
  }
}

// Appelle la fonction de vérification de maintenance
checkMaintenance();

function resizeIframe(){
  window.parent.postMessage(
      {type:'resize', height:document.body.scrollHeight}, "*")
}
setInterval(resizeIframe, 500);
resizeIframe();

